.bg__menu{
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	background-color: rgba(#000000, 0.6);
	animation: fadeIn 0.5s linear;
	z-index: 1050;

	&.hide{
		animation: fadeOut 0.5s linear;
	}
}

.form__mobile{
	display: flex;
	align-items: center;

	.carrinho__icone__mobile{
		margin-left: 10px;
		position: relative;

		.icon__mask.carrinho{
			color: #fff;
		}

		.qtd__itens{
			width: 16px;
			height: 16px;
			border-radius:50%;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			background: #f68634;
			font-size: 12px;
			font-family: 'poppinsbold';
			position: absolute;
			right: -8px;
			top: -8px;
		}
	}
}
.lista__todas__categorias{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	background-color: #fff;
	border-radius: 10px;
}

.topo__pesquisa{
    position: fixed;
    top: -150px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1080;
    max-width: 900px;
    width: 90%;
    transition: all 0.5s linear;

    &.shown{
        top: 48vh;
    }
}

.btn__toggle__categ{
	display: flex;
	align-items: center;
	background-color: transparent;
	justify-content: center;
	border: none;
	color: #fff;
	position: relative;
	z-index: 40;
	padding: 10px 5px;
	font-size: 14px;
	line-height: 14px;
	width: 100%;
	max-width: 210px;
	background-color: transparent;
	border-radius: 0px;
	font-family: poppinsbold;
	border-left: 1px solid #fff;
	min-height: 54px;

	@include media-breakpoint-down (md) {
		width: 100%;
		max-width: unset !important;
		border-left: none;
	}

	&:before{
		width: 25px;
		height: 15px;
		background-image: url('../images/icone.png');
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center center;
		content:'';
		display: block;
		z-index: -1;
		margin-right: 10px;
	}

	&[aria-expanded="true"]{
		background-color: #002b5e;
	}
}

.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;

	.dropmenu{
		list-style: none;
		padding: 0px;
		margin: 0px;

		a{
			display: block;
			padding: 10px;
			background-color: #2b3f38;
			color: #fff;
			font-family: 'poppinssemibold';
			border-bottom: 1px solid #2b3f38;
		}
	}
}

.seguro__selo{
	background-color: #4d8712;
	color: #fff;
	padding: 3px 15px;
	border-radius: 5px;
	font-size: 11px;
	line-height: 13px;

	.icon{
		margin-right: 5px;
	}

	@include media-breakpoint-down (md) {
		margin: 10px 20px;
		text-align: center;
	}
}
.pesquisa__form{
    display: flex;
    overflow: hidden;
    background-color: #fff;
    align-items: center;
	border-radius: 5px;

	@include media-breakpoint-down(sm){
		.pesquisa__btn{
			width: 30px !important;
			height: 35px !important;
			min-width: unset;
		}

		.pesquisa__input{
			height: 35px !important;
			padding: 5px !important;
		}
	}

    .pesquisa__btn{
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(244,122,53);
		background: linear-gradient(0deg, rgba(244,122,53,1) 0%, rgba(251,175,48,1) 100%);
        color: #fff;
        min-width: 50px;
        width: 50px;
        height: 50px;
        border: none;
		border-radius: 5px;
		position: relative;
    }
    .pesquisa__input{
        display: block;
        width: 100%;
        font-family: 'poppinsregular';
        font-size: 13px;
        line-height: 15px;
        padding: 18px 14px 17px 22px;
        border: none;
        background-color: #fff;
        color: #353433;
        border: none;
        border-radius: 25px;

        &::placeholder{
            color: #999999;
        }

        &:focus{
            outline: none;
        }
    }
    label{
        display: block;
        width: 100%;
        margin-bottom: 0px;
    }
}

.encerrar__item{
    padding: 10px 10px 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .encerrar__link{
        width: 198px;
        max-width: 100%;
        height: 50px;
        background-color: #fff;
        color: #f68634;
		border: 2px solid #f68634;
        font-family:'poppinsbold';
        font-size: 13px;
        line-height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
		padding-top: 2px;
        transition: all 0.3s linear;
		position: relative;

		&:after{
			width: calc(100% + 4px);
			height: calc(100% + 4px);
			background: rgb(244,122,53);
			background: linear-gradient(0deg, rgba(244,122,53,1) 0%, rgba(251,175,48,1) 100%);
			content: '';
			position: absolute;
			top: -2px;
			left: -2px;
			border-radius: 5px;
			opacity: 0;
			transition: all 0.3s linear;
		}


		span{
			position: relative;
			z-index: 200;
		}

		@include media-breakpoint-down(md) {
            height: 28px;
			border-radius: 14px;
        }

        &:hover{
            color: #fff;
            text-decoration: none;

			&:after{
				opacity: 1;
			}
        }
    }
}
.logado__nome{
    padding: 28px 20px 22px 20px;
	background: rgb(244,122,53);
	background: linear-gradient(0deg, rgba(244,122,53,1) 0%, rgba(251,175,48,1) 100%);
    color: #fff;
    font-family: 'poppinsbold';
    font-size: 16px;
    line-height: 16px;
	text-align: center;

	@include media-breakpoint-down (md) {
        padding: 12px 6px 10px 6px;
    }
}
.logado__item{
    padding: 0px 20px;
	background-color: #fff;

    .logado__link{
        display: block;
        padding: 18px 0px 18px 0px;
        border-bottom: 1px solid rgba(#353434, 0.1);
        font-size: 14px;
        line-height: 14px;
        font-family: 'poppinsregular';
        color: #353434;

        &:hover{
            color: #353434;
			text-decoration: underline;
        }

		@include media-breakpoint-down (md) {
            padding: 10px 6px 10px 6px;
        }
    }
}
.dropdown-menu{
	border: none;
	padding: 0px;
	margin: 0px;
}
@include media-breakpoint-up (lg) {
	.segura__carrinho__topo{
		position: relative;
		display: flex;
		align-items: center;

		.icon__mask{
			transition: all 0.3s linear;
		}

		.carrinho__btn__topo{
			display: flex;
			align-items: center;
			position: relative;

			.carrinho__icone__topo{
				margin-left: 0px;
			}
		}
	}

	.segura__pesquisa{
		width: 100%;
		max-width: 375px;
	}

	.logado__toggler{
		display: flex;
		align-items: center;
		border: none;
		background-color: transparent;
		font-size: 14px;
		line-height: 16px;
		color: #353434;
		padding: 5px 0px;

		&:hover{
			text-decoration: none;
			.login__icone{
				&:before{
					opacity: 1;
				}
			}
		}

		&:focus{
			outline: none;
		}
	}

	.segura__login__topo{
		font-size: 14px;
		line-height: 16px;
		color: #353434;
		position: relative;


		.login__toggler{
			display: flex;
			align-items: center;

			&:hover{
				text-decoration: none;
			}
		}
		.dropdown{
			display: none;

			&:hover{
				text-decoration: none;
			}
		}

		&.logado{
			.login__toggler{
				display: none;
			}
			.dropdown{
				display: block;
			}
		}
	}
}

@include media-breakpoint-up (lg) {
	.main__logo{
		margin-right: 20px;
	}
	.segura__right{
		display: flex;
		align-items: center;

		& > * {
			margin-left: 30px;
		}
	}
	.main__cima{
		background-color: #fff;
		color: #2b3f38;
		padding: 10px 0px 2px;
		border-bottom: 1px solid #d1d1d1;
		.container{
			display: flex;
			justify-content: flex-start;
		}
	}
	.topo{
		position: relative;
		z-index: 800;
	}
	.mobile__icone{
		display: none !important;
	}
	.topo__mobile{
		display: none;
	}
	.segura__contato{
		display: flex;
		align-items: center;
		font-size: 14px;
		line-height: 16px;

		.contato__topo{
			display: flex;
			align-items: center;
			margin-right: 30px;
			font-size: 13px;
			line-height: 15px;
		}
	}

	.logado__toggler{
		&:after{
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            border-bottom: 15px solid #fbb030;
            content: '';
            position: absolute;
            left: 30px;
            bottom: -20px;
            display: none;
			z-index: 100;
        }

        &[aria-expanded="true"] {
            &:after{
                display: block;
            }
        }
	}
	.main__meio{
		background-color: #002b5e;
		color: #fff;
		padding: 5px 0px 5px 0px;
		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;

			.segura__pesquisa{
				flex-grow: 1;
				max-width: 450px;

				@include media-breakpoint-only (lg) {
					max-width: 300px;
				}
			}
		}
	}
	.main__logo{
		min-width: 130px;
		margin-right: 50px;
	}
	.right__cima,
	.right__baixo{
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	.main__right{
		width: 100%;
		flex-grow: 1;
	}
	.segura__categorias{
		background: rgb(244,122,53);
		background: linear-gradient(0deg, rgba(244,122,53,1) 0%, rgba(251,175,48,1) 100%);
		color: #fff;
		.container{
			display: flex;
			position: relative;

		}

		.main__menu{
			display: flex;
			justify-content: space-around;
			align-items: center;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			font-size: 14px;
			line-height: 14px;
			flex-grow: 1;

			.dropdown__controls{
				button{
					display: none;
				}
			}
		}
	}
	.categoria__expanded{
		position: absolute;
		left: 15px;
		top: 100%;
		max-width: 352px;
		width: 100%;
		z-index: 600;
		background-color: #002b5e;
		border-radius: 0px 0px 15px 15px;
		box-shadow: 0px 0px 5px rgba(#000, 0.4);
		color: #353434;

		.lista__todas__categorias{
			max-height: calc(100vh - 250px);
			overflow-y: auto;
			margin-top: 15px;
			margin-bottom: 20px;
		}

		.dropdown__controls{
			position: relative;
			padding-right: 40px;

			.categoria__link{
				&:after{
					opacity: 0;
				}
			}
			&:after {
				width: calc(100% - 40px);
				left: 20px;
				bottom: 0px;
				position: absolute;
				background-color: #d1d1d1;
				content: '';
				height: 1px;
			}
			button{
				position: absolute;
				right: 20px;
				top: calc(50% - 12px);
				background-color: #002b5e;
				color: #fff;
				border: none;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0px;
				font-size: 12px;
				line-height: 12px;
				transition: all 0.3s linear;
				padding: 5px 2px 3px 3px;

				&[aria-expanded="true"]{
					transform: rotate(90deg);
				}
			}
		}

		.dropmenu{
			list-style: none;
			background-color: #002b5e;
			color: #fff;
			padding-left: 20px;
			padding-right: 20px;

			li{
				&:last-child{
					a{
						border-bottom: none;
					}
				}
			}

			a{
				display: block;
				padding: 10px;
				border-bottom: 1px solid #fff;
			}
		}

		.categoria__link{
			display: flex;
			justify-content: space-between;
			padding: 20px 5px 20px 5px;
			position: relative;
			font-family: poppinsbold;
			font-size: 14px;
			line-height: 14px;
			width: 100%;

			&:after {
				width: calc(100% - 40px);
				left: 20px;
				bottom: 0px;
				position: absolute;
				background-color: #d1d1d1;
				content: '';
				height: 1px;
			}
		}
	}
	.logado__menu{
		min-width: 256px;
        max-width: 100%;
        left: unset !important;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#353434, 0.6);
		transform: unset !important;
		top: calc(100% + 20px) !important;
		left: -10px !important;
		border-radius: 16px;
		overflow: hidden;
	}
	.menu__item{
		position: relative;
		width: 100%;

		.dropdown__controls{
			display: flex;
		}
		.menu__link{
			padding: 18px 15px;
			min-height: 54px;
			position: relative;
			display: flex;
			align-items: center;
			font-size: 14px;
			line-height: 14px;
			justify-content: center;
			text-align: center;

			img{
				margin-right: 8px;
				position: relative;
				z-index: 500;
			}

			span{
				position: relative;
				z-index: 500;
			}

			&:hover{
				text-decoration: none;
			}
		}
	}
	.segura__destaques{
		flex-grow: 1;
		position: relative;

		.main__menu{
			border-right: 1px solid #fff;
			.dropmenu{
				position: absolute;
				top: 90% !important;
				left: calc(50% - 128px);
				width: 256px;
				max-height: 300px;
				overflow-y: auto;
				background-color: #fff;
				list-style: none;
				padding-left: 0px;
				margin-bottom: 0px;
				box-shadow: 0px 0px 8px rgba(#000, 0.4);
				margin: 0px !important;
				z-index: 1000;

				li{
					display: flex;

					&:last-child{
						a{
							border-bottom: none;
						}
					}

					a{
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 10px;
						width: 100%;
						text-align: center;
						color: #fff;
						transition: all 0.3s linear;
						font-family: 'poppinsregular';
						border-bottom: 1px solid rgba(#fff, 40%);
						background-color: #f68634;

						&:hover{
							color: #fff;
							text-decoration: none;
							background-color: darken(#ff9302, 10%);
						}
					}
				}
			}
			.menu__item{
				position: relative;
				border-left: 1px solid #fff;
				z-index: 100;

				&:after{
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0px;
					left: 0px;
					background: #b54e22;
					background: linear-gradient(0deg, rgba(#b54e22,1) 0%, rgba(#fbaf30,1) 100%);
					opacity: 0;
					content: '';
					transition: all 0.3s linear;
					z-index: -1;
				}
				&:hover{
					&:after{
						opacity: 1;
					}
					.dropmenu{
						display: block;
					}
				}
			}
		}

		&:after{
			width: 100vw;
			left: -25px;
			z-index: -1;
			content: '';
			position: absolute;
			background-color: #ededed;
			top: 0px;
			height: 100%;
		}
	}
	.right__cima{
		padding: 8px 0px;
		border-bottom: 1px solid #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.right__baixo{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 0px 20px 0px;
	}
	.logado__toggler,
	.login__toggler,
	.carrinho__link__topo{
		display: flex;
		align-items: center;
		background-color: #001b3c;
		color: #fff;
		padding: 12px 22px 9px 22px;
		border-radius: 7px;
		font-size: 11px;
		line-height: 13px;
		text-align: left;
		transition: all 0.3s linear;

		&:hover{
			color: #fff;
			background: rgb(244,122,53);
			background: linear-gradient(0deg, rgba(244,122,53,1) 0%, rgba(251,175,48,1) 100%);
			text-decoration: none;

			.icon__mask{
				color: #fff;
				border: none;
			}
		}

		span,
		.numero__carrinho__topo{
			font-size: 14px;
			line-height: 16px;
		}

		.icon__mask{
			color: #f68634;
			margin-right: 10px;
		}
	}
}

.zap{
	border: 1px solid #f68634;
	color: #f68634;
	width: 22px;
	min-width: 22px;
	height: 22px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
	position: relative;
	text-decoration: none !important;

	&:hover{
		background: #f68634;
		color: #fff;
	}

	@include media-breakpoint-down(md){
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
	}
}

.contato__topo__icone,
.sociais__topo a{
	border: 1px solid #f68634;
	color: #f68634;
	width: 22px;
	min-width: 22px;
	height: 22px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
	position: relative;

	span{
		position: relative;
		z-index: 500;
	}

	&:after{
		width: calc(100% + 2px);
		height: calc(100% + 2px);
		position: absolute;
		top: -1px;
		left: -1px;
		content: '';
		background: rgb(244,122,53);
		background: linear-gradient(0deg, rgba(244,122,53,1) 0%, rgba(251,175,48,1) 100%);
		opacity: 0;
		border-radius: 5px;
	}

	&:hover{
		&:after{
			opacity: 1;
		}
		.icon__mask{
			color: #fff;
		}
	}
}
.contato__topo{
	display: flex;
	align-items: center;

	&:hover{
		text-decoration: none;
		.contato__topo__icone{
			&:after{
				opacity: 1;
			}
			.icon__mask{
				color: #fff;
			}
		}
	}
}
.sociais__topo{
	display: flex;
	align-items: center;
}

@include media-breakpoint-down (md) {
	.topo__main{
		position: fixed;
		top: 0px;
		left: -250px;
		width: 250px;
		z-index: 1080;
		height: 100%;
		overflow-y: auto;
		background-color: #002b5e;
		transition: all 0.5s linear;
		flex-direction: column;
		display: flex;

		.menu__link{
			img{
				margin-right: 10px;
			}
		}

		.main__meio{
			order: 1;

			.container{
				display: flex;
				flex-direction: column;

				.right__cima{
					order: 2;
				}
				.right__baixo{
					order: 1;
				}
			}

			.segura__pesquisa{
				display: none;
			}
		}
		.contato__topo{
			flex-direction: column;
			display: flex;
			margin-bottom: 15px;
			color: #fff;

			.contato__topo__icone{
				margin-right: 0px;
				margin-bottom: 5px;
			}
		}
		.sociais__topo{
			justify-content: center;
			margin-bottom: 20px;
			color: #fff;
		}
		.segura__categorias{
			order: 2;
			background-color: #ff9302;

			.container{
				display: flex;
				flex-direction: column;

				.btn__toggle__categ{
					order: 2;
				}
				.categoria__expanded{
					order: 3;

					.categoria__link{
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						padding: 20px 22px 20px 21px;
						position: relative;
						font-family: poppinsbold;
						font-size: 14px;
						line-height: 14px;
						width: 100%;

						span{
							display: inline-block;
							margin-bottom: 5px;

							&:last-child{
								margin-bottom: 0px;
							}
						}

						&:after {
							width: calc(100% - 40px);
							left: 20px;
							bottom: 0px;
							position: absolute;
							background-color: #d1d1d1;
							content: '';
							height: 1px;
						}
					}

					.dropdown__controls{
						position: relative;
						padding-right: 40px;

						.categoria__link{
							&:after{
								opacity: 0;
							}
						}
						&:after {
							width: calc(100% - 40px);
							left: 20px;
							bottom: 0px;
							position: absolute;
							background-color: #d1d1d1;
							content: '';
							height: 1px;
						}
						button{
							position: absolute;
							right: 20px;
							top: calc(50% - 12px);
							background-color: #002b5e;
							color: #fff;
							border: none;
							width: 24px;
							height: 24px;
							border-radius: 50%;
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 0px;
							font-size: 12px;
							line-height: 12px;
							transition: all 0.3s linear;
							padding: 5px 2px 3px 3px;

							&[aria-expanded="true"]{
								transform: rotate(90deg);
							}
						}
					}

					.dropmenu{
						list-style: none;
						background-color: #002b5e;
						color: #fff;
						padding-left: 20px;
						padding-right: 20px;

						li{
							&:last-child{
								a{
									border-bottom: none;
								}
							}
						}

						a{
							display: block;
							padding: 10px;
							border-bottom: 1px solid #fff;
						}
					}
				}
				.segura__destaques{
					order: 1;
				}
			}
		}

		.container{
			padding: 0px;
		}

		&.shown{
			left: 0px;
		}
		.segura__login__topo{
			display: none;
		}
		.segura__carrinho__topo{
			display: none;
		}
	}
	.main__logo{
		text-align: center;
		padding: 20px;
	}
	.topo__mobile{
		background-color: #002b5e;
		padding: 10px 0px;
		border-bottom: 2px solid #f68634;

		.login__toggler,
		.logado__toggler{
			padding: 10px;
			background-color: #fff;
			color: #f68634;
			border-radius: 10px;
		}

		.segura__login__topo{
			.login__toggler{
				display: flex;
			}
			.dropdown{
				display: none;
			}

			&.logado{
				.login__toggler{
					display: none;
				}
				.dropdown{
					display: block;
				}
			}
		}
		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.mbl__logo{
			max-width: 90px;
		}
	}
	.mbl__toggler{
		border: none;
		background-color: transparent;
		color: #FFF;
		font-size: 16px;
		line-height: 16px;
		padding: 0px 8px;
		margin-right: 15px;
	}
	.segura__login__topo{
		.login__icone,
		.login__texto{
			display: none;
		}
		.mobile__icone{
			padding: 0px 8px;
			border-right: 1px solid rgba(#FFF, 0.3);

			&:last-child{
				border-right: 0;
			}
		}
	}
	.segura__carrinho__topo{
		position: relative;
		.carrinho__icone{
			display: none;
		}
		.carrinho__texto{
			display: none;
		}
		.mobile__icone{
			padding: 0px 5px;
			border-left: 1px solid rgba(#fff, 0.3);
			color: #fff;
		}
	}
	.mbl__left{
		display: flex;
		align-items: center;
		width: 35%;
	}

	.mbl__right{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 65%;
		padding-left: 5%;

		.segura__btns{
			display: flex;
			align-items: center;
			margin-bottom: 10px;
		}
		.mbl__toggler{
			margin-right: 0px;
		}
	}
	.logado__menu{
        min-width: 200px;
        max-width: 100%;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#353535, 0.6);
        right: -3px !important;
		left: unset !important;
		top: calc(100% + 10px)!important;
		transform: unset !important;
    }



	.logado__toggler{
		&[aria-expanded="true"] {
			&:after{
				display: block;
			}
		}

		&:after{
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 10px solid #34215f;
			content: '';
			position: absolute;
			right: 7px !important;
			bottom: -10px;
			display: none;
			z-index: 100;
		}
	}
	.main__menu{

		.dropmenu{
			list-style: none;
			padding: 0px;
			margin: 0px;

			a{
				display: block;
				padding: 10px;
				background-color: #002b5e;
				color: #fff;
				font-family: 'poppinsregular';
				border-bottom: 1px solid #fff;
			}
		}

		.menu__link{
			padding: 10px;
			display: flex;
			align-items: center;
			font-family: 'poppinsregular';
			border-bottom: 1px solid #fff;
			color: #fff;

			.menu__link__icone{
				margin-right: 5px;
				padding-bottom: 2px;
			}
		}
	}
	.dropdown__controls{
		position: relative;

		button{
			width: 20px;
			height: 20px;
			position: absolute;
			top: calc(50% - 10px);
			right: 10px;
			border: none;
			background-color: #f68634;
			color: #fff;
			border-radius: 10px;
			padding: 3px 5px 1px 5px;
			font-size: 12px;
			line-height: 12px;
			transition: all 0.3s linear;
			display: flex;
			align-items: center;
			justify-content: center;

			&[aria-expanded="true"]{
				transform: rotate(90deg);
			}
		}
	}
	.main__cima{
		color: #f68634;
		text-align: center;
		padding: 10px;

		.contato__topo{
			margin-bottom: 20px;
		}
	}
}
