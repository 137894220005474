.rodape{
	background-color: #002b5e;
	color: #fff;
	padding-top: 70px;
}
.news__titulo{
	font-family: poppinsbold;
	font-size: 18px;
	line-height: 20px;
	margin-bottom: 15px;
	text-align: center;
}

.segura__selos{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;

	@include media-breakpoint-down(sm){
		grid-template-columns: repeat(2, 1fr);
	}

	.selo{
		width: 100%;
		height: 100px;
		position: relative;

		img{
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}
}

.news__form{
	max-width: 730px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	background-color: #fff;
	border-radius: 5px;
	margin-bottom: 50px;

	.form__news{
		width: 100%;
		flex-grow: 1;
	}

	.input__email{
		display: block;
        width: 100%;
        font-family: 'poppinsregular';
        font-size: 13px;
        line-height: 15px;
        padding: 18px 14px 17px 22px;
        border: none;
        background-color: #fff;
        color: #353433;
        border: none;
        border-radius: 5px;

        &::placeholder{
            color: #999999;
        }

        &:focus{
            outline: none;
        }
	}
	.news__btn{
		display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(244,122,53);
		background: linear-gradient(0deg, rgba(244,122,53,1) 0%, rgba(251,175,48,1) 100%);
        color: #fff;
        min-width: 90px;
        width: 90px;
        height: 50px;
        border: none;
		border-radius: 5px;
		position: relative;
	}
}

.sub__rodape{
	font-family: 'poppinsbold';
	font-size: 15px;
	line-height: 18px;
	margin-bottom: 15px;
}
.num__rodape{
	font-size: 30px;
	line-height: 32px;
	letter-spacing: -1px;
	font-family: 'poppinsbold';
	color: #fff;
	margin-bottom: 10px;
}
.rodape__titulo{
	font-family: 'poppinsbold';
	font-size: 15px;
	line-height: 18px;
	margin-bottom: 15px;
}

.rodape__menu{
	font-family: 'poppinsregular';
	font-size: 13px;
	line-height: 15px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	a{
		display: inline-block;
		padding: 3px 0px;
		margin-bottom: 5px;
	}
}
.info__secao{
	background: rgb(244,122,53);
	background: linear-gradient(0deg, rgba(244,122,53,1) 0%, rgba(251,175,48,1) 100%);
	color: #fff;
	padding: 25px 0px;
}
.info__grid{
	display: flex;
	justify-content: space-between;

	@include media-breakpoint-down (md) {
		flex-direction: column;
		align-items: center;

		.info__card{
			flex-direction: column;
			align-items: center;
			text-align: center;
			margin-bottom: 15px;

			.info__card__imagem{
				margin-right: 0px;
				margin-bottom: 10px;
			}
		}
	}
}
.info__card{
	display: flex;
	align-items: center;

	.info__card__imagem{
		margin-right: 10px;
	}
}
.info__card__titulo{
	font-family: poppinsextrabold;
	font-size: 15px;
	line-height: 17px;
}
.info__card__desc{
	font-size: 13px;
	line-height: 15px;
}
.rodape__item{
	margin-bottom: 15px;
}
.sociais__rodape{
	display: flex;
	align-items: center;

	a{
		border: 1px solid #f68634;
		color: #f68634;
		width: 30px;
		min-width: 30px;
		height: 30px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 5px;
		position: relative;

		span{
			position: relative;
			z-index: 500;
		}

		&:after{
			width: calc(100% + 2px);
			height: calc(100% + 2px);
			position: absolute;
			top: -1px;
			left: -1px;
			content: '';
			background: rgb(244,122,53);
			background: linear-gradient(0deg, rgba(244,122,53,1) 0%, rgba(251,175,48,1) 100%);
			opacity: 0;
			border-radius: 5px;
			transition: all 0.3s linear;
		}

		&:hover{
			&:after{
				opacity: 1;
			}
			.icon__mask{
				color: #fff;
			}
		}
	}
}
.row__pagamento{
	padding-top: 50px;
}
.titulo__pagamento,
.selo__titulo{
	font-family: poppinsbold;
	font-size: 15px;
	line-height: 18px;
	margin-bottom: 16px;
}
.selos__rodape{
	.seguro__selo{
		display: inline-flex;
		align-items: center;
	}
}
.rodape__creditos{
	font-family: 'poppinsregular';
	font-size: 12px;
	line-height: 14px;
	align-items: center;
	padding-top: 20px;
	border-top: 1px solid rgba(#fff, 0.7);
	padding-bottom: 20px;
	margin-top: 100px;
}
.gv8__box{
	display: flex;
	align-items: center;
	font-family: 'poppinsregular';
	font-size: 12px;
	line-height: 14px;
	justify-content: center;
}
.gv8__logo{
	margin-left: 5px;
}
.creditos{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
#voltar__topo{
	position: fixed;
	right: 10px;
	bottom: 45px;
	transition: all 0.5s linear;
	width: 40px;
	height: 40px;
	border-radius: 10px;
	background-color: #002b5e;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 0px 5px rgba(#fff, 0.7);
	z-index: 999;

	&.active{
		bottom: 45px;

		@include media-breakpoint-down (sm) {
			bottom: 105px;
		}
	}

	@include media-breakpoint-down (sm) {
		bottom: 105px;
	}
}
