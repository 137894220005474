// bg-position(x,y) width height
$icons: (
	'caminhao': 0px -70px 18px 13px,
	'carrinho': -42px 0px 18px 18px,
	'carrinho2': -94px -28px 13px 13px,
	'email': -55px -70px 12px 8px,
	'facebook': -117px 0px 5px 11px,
	'facebook2': -54px -42px 6px 15px,
	'fogo': -70px 0px 14px 18px,
	'html': 0px 0px 32px 32px,
	'instagram': -94px -73px 10px 10px,
	'instagram2': 0px -93px 14px 14px,
	'lixo': -24px -93px 9px 12px,
	'login': -28px -42px 16px 18px,
	'lupa': 0px -42px 18px 18px,
	'ofertas': -70px -28px 14px 18px,
	'seguros': -94px 0px 13px 18px,
	'tel': -94px -51px 12px 12px,
	'youtube': -43px -93px 12px 8px,
	'youtube2': -28px -70px 17px 11px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	background-size: 122px 107px;

	display: inline-block;
	vertical-align: middle;

}

.icon__mask{
	mask-image: url('../images/sprites.png');
	mask-repeat:no-repeat;
	mask-size: 122px 107px;

	display: inline-block;
	vertical-align: middle;
	background-color: currentColor;
}

@each $icon,$value in $icons {
	.#{$icon} {
		width: nth($value,3);
		height: nth($value,4);
		$sprite-position: #{nth($value,1)} #{nth($value,2)};

		&.icon{
			background-position: $sprite-position;
		}

		&.icon__mask{
			mask-position: $sprite-position;
		}
	}
}
