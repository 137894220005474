.interna{
    padding: 100px 0px 110px 0px;
}
.titulo__interna{
    font-family: 'poppinsbold';
    font-size: 30px;
    line-height: 34px;
    color: #002b5e;
    margin-bottom: 50px;
	position: relative;
	padding-bottom: 10px;

	&:before{
		background: #f68634;
		content: '';
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
		height: 5px;
	}
}
.minha__conta__nav{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    a{
        display: block;
        padding: 15px 10px;
        font-size: 15px;
        line-height: 15px;
        max-width: 300px;
        border-bottom: 1px solid rgba(#353535, 0.4);
        width: 100%;
		text-align: center;
    }
}
.conta__interna{
	.titulo__interna{
		text-align: center;
	}
}
.conteudo__btn{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	color: #f68634;
	min-width: 250px;
	height: 50px;
	transition: all 0.3s linear;
	font-family: 'poppinsbold';
	font-size: 14px;
	line-height: 14px;
	border: 2px solid #f68634;
	border-radius: 5px;
	position: relative;

	&:after{
		position: absolute;
		content: '';
		top: -2px;
		left: -2px;
		background: rgb(244,122,53);
		background: linear-gradient(0deg, rgba(244,122,53,1) 0%, rgba(251,175,48,1) 100%);
		border-radius: 5px;
		z-index: 300;
		width: calc(100% + 4px);
		height: calc(100% + 4px);
		opacity: 0;
		transition: all 0.3s linear;
		z-index: 200;
	}

	span{
		position: relative;
		z-index: 500;
	}


	&.voltar__btn{
		min-width: 154px;
	}

	&:focus{
		outline: none;
	}

	&:hover{
		color: #fff;
		text-decoration: none;


		&:after{
			opacity: 1;
		}
	}
}
.custom__check__input{
	position: fixed;
	left: -100vw;

	&:checked{
		& ~ .custom__check__label{
			&:after{
				opacity: 1;
			}
		}
	}
}
.custom__check__label{
	position: relative;
	padding-left: 24px;
	cursor: pointer;
	color: #353434;
	font-family: poppinsregular;
	font-weight: normal;
	margin-left: 30px;

	a{
		text-decoration: underline;
	}

	&:before{
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0px;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		border: 2px solid #002b5e;
		transition: all 0.3s linear;
	}

	&:after{
		content: '';
		position: absolute;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #002b5e;
		top: 50%;
		left: 4px;
		transform: translateY(-50%);
		transition: all 0.3s linear;
		opacity: 0;
	}
}
.checkbox__custom{
	padding-top: 10px;
}
.segura__contato__btn{
	padding-top: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.paginacao{
	display: flex;
	justify-content: flex-start;
    flex-wrap: wrap;
    border-radius: none;
    border: none;
    list-style: none;
    padding-left: 0px;
	padding-top: 30px;

	.paginacao__item{
		a{
            font-family: 'poppinsbold';
            font-size: 15px;
            line-height: 15px;
            color: #353434;
			border: 1px solid #353434;
            display: flex;
            width: 35px;
			min-width: 35px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            height: 35px;
            border-radius: 50%;
            position: relative;
            transition: all 0.3s linear;
            margin: 0px 2px;
            padding: 0px;

            &:focus{
                box-shadow: none;
            }
        }

        &.active{
           a{
				background: #2b3f38;
				border-color: #2b3f38;
                color: #fff;
           }
        }

		&:hover{
			a{
                text-decoration: none;
            }
		}
	}
}
.revender__img{
	margin-bottom: 25px;
	text-align: center;
}
.revender__form{
	margin-top: 40px;
	padding-top: 40px;
	border-top: 5px solid #ededed;

	.cadastro__titulo{
		margin-bottom: 28px;
	}
	.form-group{
		margin-bottom: 10px;
	}
}
.encontrar__locals{
	.encontrar__item{
		display: flex;
		margin-bottom: 34px;

		.encontrar__item__icone{
			width: 40px;
			height: 40px;
			min-width: 40px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 15px;
			background-color: #014e2a;
			font-size: 20px;
			line-height: 20px;
			color: #fff;
		}

		.encontrar__item__conteudo{
			padding-top: 10px;
		}

		.encotrar__item__titulo{
			font-family: poppinsbold;
			color: #353434;
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 7px;
		}
	}
}
.row__encontrar{
	align-items: center;
}
.encontrar__titulo{
	font-size: 20px;
	line-height: 24px;
	color: #000;
	font-family: poppinsbold;
	margin-bottom: 28px;
}
.sub__interna{
	font-family: poppinsbold;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 40px;
	color: #000;
}
@include media-breakpoint-up (lg) {
	.select__estado{
		display: none;
	}
}
.encontrar__locals{
	@include media-breakpoint-down (md) {
		padding-top: 20px;
	}
}
.modal__link{
	.modal-dialog{
		margin-left: auto;
		margin-right: auto;
		max-width: 920px;
		width: 100%;
		.modal-content{
			background-color: transparent;
			border: none;
		}
		.modal-body{
			background-color: transparent;
			border: none;
			padding: 0px;
			position: relative;

			button{
				position: absolute;
				top: 20px;
				right: 20px;
				font-size: 25px;
				background-color: transparent;
				border: none;
				line-height: 28px;
				color: #2b3f38;

				&:focus{
					outline: none;
				}
			}
		}
	}
}
.contato__btn__center{
	display: flex;
	justify-content: center;
}

#modalPopUp{
	.modal-content{
		border: 0;
		background: transparent;
	}



	.modal-header{
		border-bottom: 0;

		.close{
			color: #fff;
			opacity: 1;
			font-size: 14px;
			font-family: 'poppinsregular';
			font-weight: 300;
			padding-right: 100px;
		}
	}

	.modal-body{
		background-color: #5abde5;
		border-radius: 0;
		padding: 50px 30px;
		width: 100%;
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;

		.row{
			display: flex;
			align-items: center;

			@include media-breakpoint-down(md){
				flex-direction: column;
				justify-content: center;
			}

			.box__info{

				span{
					font-family: 'poppinsbold';
				}

				.title{
					color: #fff;
					font-size: 25px;
					line-height: 30px;
					font-family: 'poppinsregular';
					position: relative;
					margin-bottom: 5px;
					width: 100%;
					max-width: 262px;

					&::after{
						content: '';
						width: 124px;
						height:99px;
						background: url(../images/cupons.png);
						background-repeat: no-repeat;
						background-position: center center;
						position: absolute;
						right: -5px;
						bottom: -15px;
					}
				}

				.subtitle{
					color: #18529d;
					font-size: 51px;
					line-height: 52px;
					font-family: 'poppinsextrabold';
					margin-bottom: 25px;
				}
			}

			.box__form{
				@include media-breakpoint-down(md){
					margin-top: 30px;
				}
				span{
					font-family: 'poppinsbold';
				}

				.title{
					color: #fff;
					font-size: 30px;
					line-height: 32px;
					font-family: 'poppinsregular';
					position: relative;
					margin-bottom: 12px;
				}

				.form__promo{
					width: 100%;
					padding: 0;

					.form-group{
						padding: 0;
						width: 100%;
						margin-bottom: 10px;
					}

					.contato__input{
						width: 100%;
						height: 50px;
						border: 0;
					}

					.btn__enviar{
						width: 185px;
						height: 50px;
						border-radius: 5px;
						color: #fff;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 13px;
						font-family: 'poppinsbold';
						background: rgb(251,174,48);
						background: linear-gradient(180deg, rgba(251,174,48,1) 0%, rgba(244,124,52,1) 100%);

						@include media-breakpoint-down(md){
							margin-left: auto;
							margin-right: auto;
						}
					}
				}
			}
		}
	}
}
