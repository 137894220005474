.banner__carousel{
	max-width: 1920px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	.carousel-indicators{
		li{
			border: none;
			width: 15px;
			height: 15px;
			border-radius: 50%;
			margin: 0px 5px;
			opacity: 1;

			&.active{
				background-color: #002b5e;
			}
		}
	}

}
.lg__carousel{
	@include media-breakpoint-down(md) {
		display: none;
	}
}
.mbl__carousel{
	@include media-breakpoint-up(lg) {
		display: none;
	}
}
.categorias{
	padding: 80px 0px;
}

.carousel__mobile{
	@include media-breakpoint-up(md){
		display: none !important;
	}
}
.categ__card{
	display: flex;
	width: 100%;
	max-width: 265px;
	min-height: 150px;
	align-items: center;
	background-image: url('../images/bg__categ.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding: 20px 20px;
	justify-content: center;
	color: #fff;
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;

	&:hover{
		text-decoration: none;
		color: #f68634;
	}

	.categ__imagem{
		margin-right: 6px;
		min-width: 90px;
		text-align: center;
	}

	.categ__texto{
		.categ__sub{
			font-size: 12px;
			line-height: 14px;
			margin-bottom: 2px;
		}
		.categ__titulo{
			font-size: 15px;
			line-height: 17px;
			font-family: poppinsextrabold;
		}
	}
}
.categ__grid{
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 20px;

	@include media-breakpoint-down (lg) {
		grid-template-columns: repeat(2, 1fr);
	}
	@include media-breakpoint-down (sm) {
		display: none;
	}

}

.carousel__relacionados, .carousel__produtos, .produtos__owl{
	@include media-breakpoint-down(sm){
		.owl-item{
			padding: 5px;
		}
		.produto__card{

			.btns{
				display: flex;
				flex-direction: column;

				.btn__orcamento{
					margin-top: 12px;
				}
			}
		}
	}
}



.produto__card{
	display: block;
	max-width: 265px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	border: 1px solid #e4e4e4;
	border-radius: 10px;
	overflow: hidden;

	&:hover{
		text-decoration: none;
	}

	.produto__card__conteudo{
		display: block;
		padding-left: 12px;
		padding-right: 12px;
	}

	.produto__card__imagem{
		position: relative;
		margin-bottom: 18px;
		background-color: #e4e4e4;
		border-radius: 16px 16px 0px 0px;
		text-align: center;

		.produto__card__tags{
			position: absolute;
			right: 10px;
			top: 10px;
			display: flex;
			flex-direction: column;
			z-index: 20;

			.produto__card__tag{
				width: 80px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #bf0811;
				color: #fff;
				font-family: 'poppinssemibold';
				font-size: 13px;
				line-height: 15px;
				margin-bottom: 5px;
				border-radius: 5px;
				text-align: center;

				.icon{
					margin-right: 5px;
				}
			}
		}
	}
	.produto__stars{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 12px;
		line-height: 14px;
		margin-bottom: 5px;

		.rating__stars{
			color: rgba(#ffae00, 40%);
			margin-right: 5px;
			i{
				position: relative;
			}
		}
		.rating__stars .fa-star:after {
			  position: absolute;
			color: #ffae00;
			left: 0;
		}
		.rating__stars[data-rating="0.5"] .fas:nth-child(1):after {
			  content: "";
		}

		.rating__stars[data-rating="1.5"] .fas:nth-child(2):after {
			  content: "";
		}

		.rating__stars[data-rating="2.5"] .fas:nth-child(3):after {
			  content: "";
		}

		.rating__stars[data-rating="3.5"] .fas:nth-child(4):after {
			 content: "";
		}

		.rating__stars[data-rating="4.5"] .fas:nth-child(5):after {
			 content: "";
		}

		.rating__stars[data-rating="5.5"] .fas:nth-child(6):after {
			 content: "";
		}

		.rating__stars[data-rating="1"] .fas:nth-child(1),
		.rating__stars[data-rating="1.5"] .fas:nth-child(1) {
			  color: #ffae00;
		}

		.rating__stars[data-rating="2"] .fas:nth-child(1),
		.rating__stars[data-rating="2"] .fas:nth-child(2),
		.rating__stars[data-rating="2.5"] .fas:nth-child(1),
		.rating__stars[data-rating="2.5"] .fas:nth-child(2) {
			  color: #ffae00;
		}

		.rating__stars[data-rating="3"] .fas:nth-child(1),
		.rating__stars[data-rating="3"] .fas:nth-child(2),
		.rating__stars[data-rating="3"] .fas:nth-child(3),
		.rating__stars[data-rating="3.5"] .fas:nth-child(1),
		.rating__stars[data-rating="3.5"] .fas:nth-child(2),
		.rating__stars[data-rating="3.5"] .fas:nth-child(3) {
			  color: #ffae00;
		}

		.rating__stars[data-rating="4"] .fas:nth-child(1),
		.rating__stars[data-rating="4"] .fas:nth-child(2),
		.rating__stars[data-rating="4"] .fas:nth-child(3),
		.rating__stars[data-rating="4"] .fas:nth-child(4),
		.rating__stars[data-rating="4.5"] .fas:nth-child(1),
		.rating__stars[data-rating="4.5"] .fas:nth-child(2),
		.rating__stars[data-rating="4.5"] .fas:nth-child(3),
		.rating__stars[data-rating="4.5"] .fas:nth-child(4) {
			  color: #ffae00;
		}
		.rating__stars[data-rating="5"] {
			  color: #ffae00;
		}
	}
	.produto__card__titulo{
		--linhas: 2;
		font-family: 'poppinssemibold';
		font-size: 14px;
		line-height: 16px;
		min-height: 32px;
		text-align: left;
		margin-bottom: 10px;
		color: #353434;
	}
	.produto__card__valor{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: #353434;
		font-size: 16px;
		line-height: 18px;
		font-family: 'poppinsextrabold';

		.produto__valor__promocao{
			font-size: 13px;
			line-height: 15px;
			margin-right: 5px;
			font-family: poppinsregular;

			span{
				text-decoration: line-through;
			}
		}
	}
	.produto__card__parcelas{
		text-align: left;
		font-size: 12px;
		line-height: 14px;
		margin-bottom: 10px;
		color: #353434;
	}
}
.mbl__home__btn{
	display:flex;
	justify-content: center;
	padding-top: 20px;

	@include media-breakpoint-up (lg) {
		display: none;
	}
}
.produtos__header{
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include media-breakpoint-down (md) {
		.home__btn{
			display: none;
		}
	}
}
.titulo__secao{
	font-family: poppinsbold;
	font-size: 35px;
	line-height: 37px;
	position: relative;
	padding-left: 30px;
	color: #002b5e;

	&:before{
		width: 15px;
		height: 90%;
		background: rgb(244,122,53);
		background: linear-gradient(0deg, rgba(244,122,53,1) 0%, rgba(251,175,48,1) 100%);
		content: '';
		position: absolute;
		left: 0px;
		top: 5%;
	}
}
.home__btn{
	position: relative;
	border: 2px solid #f68634;
	color: #f68634;
	border-radius: 5px;
	min-width: 140px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	font-size: 12px;
	line-height: 12px;

	&:hover{
		color: #fff;
		text-decoration: none;

		&:after{
			opacity: 1;
		}
	}

	&:after{
		position: absolute;
		content: '';
		top: -2px;
		left: -2px;
		background: rgb(244,122,53);
		background: linear-gradient(0deg, rgba(244,122,53,1) 0%, rgba(251,175,48,1) 100%);
		border-radius: 5px;
		z-index: 300;
		width: calc(100% + 4px);
		height: calc(100% + 4px);
		opacity: 0;
		transition: all 0.3s linear;
	}

	span{
		position: relative;
		z-index: 500;
	}
}
.produtos__header{
	margin-bottom: 40px;
}
.produto__card__botoes{
	padding-left: 12px;
	padding-right: 12px;
	padding-bottom: 20px;
	button,
	a{
		position: relative;
		border: 2px solid #f68634;
		color: #f68634;
		border-radius: 5px;
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
		font-size: 12px;
		line-height: 12px;
		font-family: poppinssemibold;
		background-color: transparent;

		.icon__mask{
			margin-right: 5px;
		}

		&:hover{
			color: #fff;
			text-decoration: none;

			&:after{
				opacity: 1;
			}
		}

		&:after{
			position: absolute;
			content: '';
			top: -2px;
			left: -2px;
			background: rgb(244,122,53);
			background: linear-gradient(0deg, rgba(244,122,53,1) 0%, rgba(251,175,48,1) 100%);
			border-radius: 5px;
			z-index: 300;
			width: calc(100% + 4px);
			height: calc(100% + 4px);
			opacity: 0;
			transition: all 0.3s linear;
		}

		span{
			position: relative;
			z-index: 500;
		}
	}
}
.produtos__owl.owl-carousel{
	position: relative;

	.owl-nav{
		button.owl-prev,
		button.owl-next{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 36px;
			height: 36px;
			border: 2px solid #002b5e;
			border-radius: 50%;
			color: #002b5e;
			position: absolute;
			top: calc(50% - 18px);
			background-color: transparent;
			font-size: 20px;
			line-height: 20px;
			transition: all 0.3s linear;
			padding: 0px;

			&:hover{
				background-color: #002b5e;
				color: #fff;
			}
		}
		button.owl-prev{
			left: -15px;
			padding-right: 2px;
		}
		button.owl-next{
			right: -15px;
			padding-left: 2px;
		}
	}
}
.produtos{
	padding-bottom: 70px;
}
.bannerszinhos{
	padding-bottom: 40px;
}
.banner__link{
	display: block;
	width: 100%;
	max-width: 540px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
}
