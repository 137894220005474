
.carrinho__header{
	display: flex;
	justify-content: center;
	font-size: 14px;
	line-height: 18px;
	color: #fff;
	background: rgb(244,122,53);
	background: linear-gradient(0deg, rgba(244,122,53,1) 0%, rgba(251,175,48,1) 100%);
	font-family: 'poppinsbold';
	border-radius: 0px;
	overflow: hidden;
}
.carrinho__produto{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
}
.carrinho__quantidade{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
	text-align: center;
}
.carrinho__valor__uni{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
	text-align: center;
    font-family: 'poppinsbold';
}
.carrinho__valor__total{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
	text-align: center;
    font-family: 'poppinsbold'
}
.carrinho__produto__card{
	display: flex;
	position: relative;
}
.btn__excluir{
	position: absolute;
	top: -4px;
	left: 5px;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	background-color: #002b5e;
	z-index: 10;
	border: none;
	font-size: 12px;
	color: #fff;
	transition: all 0.3s linear;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover{
		background-color: #f68634;
		color: #fff;
	}
	&:focus{
		outline: none;
	}

	span{
		padding-top: 2px;
		padding-left: 1px;
	}
}
.carrinho__produto__img{
	margin-right: 20px;
	min-width: 91px;
}
.carrinho__item{
	display: flex;
	font-family: 'poppinsbold';
	border-left: 2px solid #ededed;
	border-right: 2px solid #ededed;


	&:last-child{
		border-bottom: 2px solid #ededed;
	}
}
.carrinho__produto__desc{
    padding-top: 0px;
    font-family: 'poppinsregular';
    color: #353535;
}
.input-group{
	display: flex;
    justify-content: center;

    .input-group-append{
         &:nth-child(1){
            button{
                padding-bottom: 5px;
            }
        }
    }
}
.carrinho__card{
	border: 2px solid #d1d1d1;
	padding: 25px 15px;
	border-radius: 15px;
	margin-left: 0px;
	margin-right: 0px;
	margin-bottom: 30px;
}
.btn__quantidade{
	border: none;
	display: flex;
	width: 34px;
	height: 38px;
	align-items: center;
	justify-content: center;
    background-color: #313131;
	border: 2px solid #313131;
	color: #fff;
	transition: all 0.3s linear;
    font-family: 'poppinsregular';
    font-size: 20px;
    line-height: 20px;
    padding-top: 2px;

	&:hover{
		text-decoration: none;
		background-color: #353535;
		color: #fff;
		border: 2px solid #353535;
	}

	&:focus{
		outline: none;
	}
}
.carrinho__valor__promocao{
	font-size: 14px;
	line-height: 18px;
	margin-right: auto;
	margin-left: auto;
    text-align: left;
    color: #353535;
	text-decoration: line-through;
	font-family: 'poppinsbold';
}
.carrinho__valor__unidade{
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	margin-right: auto;
	margin-left: auto;
    font-family: 'poppinsbold';
    color: #353535;
}
.numero__valor__unidade{
	font-size: 20px;
	line-height: 20px;
	margin-left: 3px;
    font-family: 'poppinsbold';
    color: #353535;
}
.carrinho__item{
	.carrinho__produto{
		padding-top: 28px;
		padding-bottom: 20px;
		border-right: 4px solid #ededed;
	}
	.carrinho__valor__uni{
		padding-top: 28px;
		padding-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		border-right: 4px solid #ededed;
	}
	.carrinho__quantidade{
		padding-top: 28px;
		padding-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		border-right: 4px solid #ededed;
	}
	.carrinho__valor__total{
		padding-top: 28px;
		padding-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: 'poppinsregular';
		font-size: 20px;
		line-height: 20px;
        flex-direction: column;
        color: #353535;
	}
}

@include media-breakpoint-up (lg) {
	.carrinho__produto{
		width: 38%;
	}
	.carrinho__quantidade{
		width: 23%
	}
	.carrinho__valor__uni{
		width: 22%;
	}
	.carrinho__valor__total{
		width: 17%;
	}
}

@include media-breakpoint-down (md) {
	.carrinho__item{
		flex-direction: column;
	}
	.carrinho__header{
		display: none;
	}
	.carrinho__produto__card{
		flex-direction: column;
		text-align: center;
	}
	.carrinho__produto__img{
		margin-right: 0px;
	}
	.carrinho__item{
		border-right: 1px solid #ededed;
		border-bottom: 1px solid #ededed;
		border-left: 1px solid #ededed;
		padding: 0px;
		margin-bottom: 50px;

		.carrinho__produto,
		.carrinho__quantidade,
		.carrinho__valor__uni,
		.carrinho__valor__total{
			position: relative;
			border: none;
			padding-top: 0px;
			padding-bottom: 10px;
			padding-left: 0px;
			padding-right: 0px;

			&:before{
				content: attr(data-text);
				display: block;
				text-align: center;
				width: 100%;
				padding: 10px 20px;
				color: #fff;
				font-family: 'poppinsbold';
				font-size: 16px;
				line-height: 20px;
				background-color: #353535;
				margin-bottom: 20px;
			}
		}
	}
	.btn__excluir{
		position: absolute;
		left: 30px;
	}
}
.titulo__quantidade__carrinho{
	font-family: 'poppinsbold';
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	margin-bottom: 5px;
	color: #353434;
}
.quantidade__valor__numero{
	font-family: 'poppinsbold';
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	margin-top: 10px;
	color: #353434;
}
.carrinho__produto__titulo{
	font-family: poppinsbold;
	margin-bottom: 16px;
	max-width: 210px;
	width: 100%;
	color: #353434;
}
.carrinho__produto__tamanho{
	span{
		font-family: poppinsbold;
	}
}
.carrinho__simulacao__desc{
	font-size: 16px;
	line-height: 20px;
	font-family: poppinsbold;
	margin-bottom: 20px;
	color: #353535;
}
.simulacao__cep__input{
	display: block;
	width: 100%;
	height: auto;
	padding: 16px 20px 16px 20px;
	background-color: #fff;
	color: #353535;
    font-family: 'poppinsregular';
	font-size: 14px;
	line-height: 18px;
	border-radius: 5px;
	border: none;
	border: 1px solid #d1d1d1;

	&:focus{
		outline: none;
	}
}
.simulacao__cep__desc{
	text-align: center;
	padding-top: 20px;
}
.frete__aviso{
	background-color: #009045;
	color: #fff;
	font-family: poppinsbold;
	font-size: 13px;
	line-height: 15px;
	display: flex;
	justify-content: center;
	min-height: 30px;
	padding: 10px;
	border-radius: 20px;

	.icon{
		margin-right: 5px;
	}
}
.total__carrinho__secao{
	background-color: #ededed;
	border-radius: 15px;
	padding-bottom: 30px;
	padding-left: 30px;
	padding-right: 30px;


	.col-6{
		&:nth-child(1) {
			padding-left: 0px;
		}
		&:nth-child(2) {
			padding-right: 0px;
		}
	}
	.col-5{
		padding-left: 0px;
	}
	.col-7{
		padding-right: 0px;
	}
}
.parcelamento__carrinho{
	font-size: 12px;
	line-height: 14px;
	font-family: poppinsregular;
}
.item__total__carrinho{
	padding-top: 26px;
	padding-bottom: 26px;
	border-bottom: 1px solid #353535;
	color: #353535;
	font-family: poppinsbold;
	font-size: 16px;
	line-height: 20px;
	margin-right: 0px;
	margin-left: 0px;

	&.row{
		& > *{
			&:nth-child(2) {
				color: #353434;
			}
		}
	}

	&:nth-child(4) {
		border-bottom: unset !important;
	}

	.col-6{
		&:nth-child(2) {
			text-align: right;
		}
	}
	.col-7{
		text-align: right;
	}

	.conteudo__btn{
		min-width: 110px;
		margin-left: -15px;
	}
	.simulacao__cep__input{
		padding-right: 40px;
	}
}
.carrinho__botoeszoes{
	.conteudo__btn{
		margin: 10px 0px;
		border-color: #d64d00;
		color: #d64d00;

		&:hover{
			background-color: #d64d00;
			color: #fff;
		}
	}
}
.total__carrinho__form{
	display: flex;
	align-items: center;
	border-radius: 5px;
	overflow: hidden;
	position: relative;

	input{
		border-radius: 5px;
		padding-right: 200px;
		color: #353535;

		&::placeholder{
			color: #353535;
		}
	}

	.conteudo__btn{
		background-color: #fff;
		color: #f68634;
		width: 110px;
		right: 0px;
		border-color: #f68634;
		margin-left: 15px;

		&:hover{
			color: #ffff;
		}
	}
}
.carrinho__simulacao__cep{
	display: flex;
	align-items: flex-start;
	width:  100%;

	@include media-breakpoint-down (sm) {
		flex-direction: column;
	}

	.carrinho__simulacao__form{
		width: 100%;
		display: block;
		margin-right: 30px;
	}
}
.simulacao__cep__link{
	text-align: right;
	display: block;
	margin-top: 5px;
}
.col__carrinho__simulacao{
	padding-top: 25px;
	padding-left: 30px;
	padding-right:30px;
	padding-bottom: 20px;
	position: relative;
	border: 2px solid #d1d1d1;
	position: relative;
	border-radius: 15px;

	@include media-breakpoint-down (md) {
		margin-bottom: 30px;
	}
}
.inline-group{
	border: none;
	max-width: 170px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;

	.input-group-prepend,
.input-group-append{
	margin-left: unset !important;
	margin-right: unset !important;
	button{
		height: 23px;
		width: 23px;
		transition: all 0.3s linear;
		border-radius: 5px !important;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		font-size: 10px;
		line-height: 10px;
		background: #002b5e;
		border: none;
		color: #fff;
		padding: 0px;

		i{
			position: relative;
			z-index: 50;
		}

		&:hover{
			color: #fff;
			background: rgb(244,122,53);
			background: linear-gradient(0deg, rgba(244,122,53,1) 0%, rgba(251,175,48,1) 100%);
		}

		&:focus{
			outline: none;
			box-shadow: none;
		}
	}
}

input{
	height: 34px;
	max-width: 34px;
	width: 34px;
	border: none;
	background-color: #fff;
	color: #353434;
	text-align: center;
	font-family: 'poppinsregular';
	border: 1px solid #dcdcdc;
	padding: 0px;
	pointer-events: none;
	border-radius: 5px !important;
	margin: 0px 5px;

	&::placeholder{
		color: #353434;
	}

	&:focus{
		background-color: #fff;
		color: #353434;
	}
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

}
